import {decrypt2} from "@/utils/dataSecure";
// import {ApiEncryptedResponse} from "@/modules/apiResponse";
import {IAuthResponse} from "@/modules/authTypes";
import baseApi from "@/services/baseApi";

export const authApi =baseApi.injectEndpoints({
    endpoints:(builder)=>({
        getLoginByAccount:builder.mutation({
            query:({url,data})=>({
                url:url,
                method:'post',
                body:data
            }),
            transformResponse:(response:IAuthResponse):IAuthResponse => decrypt2(response)
            // transformResponse:(response:ApiEncryptedResponse):IAuthResponse => decrypt(response)
        }),

    }),
    overrideExisting: false,
})

export const {useGetLoginByAccountMutation}=authApi