// import crypto from "crypto";
import crypto from "@/modules/_crypto-browserify@3.12.0@crypto-browserify/index";
import * as process from "process";
import {ApiResponse} from "@/modules/apiResponse";
window.Buffer = window.Buffer || require("buffer").Buffer;
const KEY = process.env.REACT_APP_CRYPT_KEY
const METHOD = process.env.REACT_APP_CRYPT_METHOD
if (!KEY){
    throw new Error("REACT_APP_EN_DE_CRYPT_KEY is null")
}
if (!METHOD){
    throw new Error( "REACT_APP_EN_DE_CRYPT_METHOD is null")
}
function generateIV(length:number) {
    let result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const encrypt = function (text:string) {
    const iv = generateIV(16);
    // @ts-ignore
    const cipher = crypto.createCipheriv(METHOD, Buffer.from(KEY),  iv);
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return { iv: iv, encryptedData: encrypted.toString('hex') };
};

export const decrypt2 = function (text:any):ApiResponse {
		return text;
};
export const decrypt = function (text:{
    encryptedData: string,
    iv: string
}):ApiResponse {
    const iv = Buffer.from(text.iv);
    const encryptedText = Buffer.from(text.encryptedData, 'hex');
    // @ts-ignore
    const decipher = crypto.createDecipheriv(METHOD, Buffer.from(KEY), iv);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return extractJson(decrypted.toString());
};

export const extractJson = function (data: string) {
    var s = data
        .replace(/\\n/g, "\\n")
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, "\\&")
        .replace(/\\r/g, "\\r")
        .replace(/\\t/g, "\\t")
        .replace(/\\b/g, "\\b")
        .replace(/\\f/g, "\\f");
    // eslint-disable-next-line no-control-regex
    s = s.replace(/[\u0000-\u0019]+/g, "");
    var o = JSON.parse(s);
    return o;
};




