import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import baseApi from "@/services/baseApi";
// Combine all reducers.
const rootReducer = combineReducers({
    authReducer,
    [baseApi.reducerPath]:baseApi.reducer
});

export default rootReducer;
