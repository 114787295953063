import { createSlice} from "@reduxjs/toolkit";

import { IAuthID } from "@/modules/authTypes";
import {RootState} from "@/store/store";

const initialState_INFO: IAuthID = {
    userId: "",
    accessToken: ""
}

const authSlice = createSlice({
    name: 'auth',
    initialState:initialState_INFO,
    reducers: {
        login_success: (state, action) =>  action.payload,
        logout_success: (state,action) => initialState_INFO
    },
})

export const {login_success,logout_success} =authSlice.actions
export const auth = (status:RootState)=>status.authReducer
export default authSlice.reducer