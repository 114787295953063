import {AnyAction, configureStore} from '@reduxjs/toolkit'
import rootReducer from "./rootReducer";
import {ThunkAction} from "redux-thunk";
import logger from 'redux-logger';
import {authApi} from "@/services/authApi";


// 中间件集合
const middlewareHandler = (getDefaultMiddleware: any) => {
    return [
        ...getDefaultMiddleware(),
        authApi.middleware
    ];
};

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware:(getDefaultMiddleware)=>{
        if (process.env.NODE_ENV !== 'production'){
            return  middlewareHandler(getDefaultMiddleware).concat(logger)
        }else {
            return  middlewareHandler(getDefaultMiddleware)
        }
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
// 默认异步action返回值为void,当有返回值时
// AppThunk<Promise<SomeReturnType>>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    AnyAction
    >

export default store