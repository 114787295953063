// @ts-ignore
import styled from  'styled-components'
import { Form, Input } from 'antd';
import logoBackground from "@/assets/images/logoBackground.png";

const FormItem = Form.Item;

export const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    @media screen and (max-width: 400px) {
        height: auto;
    }
`

export const LoginInnerContainer = styled.div`
    width: auto;
    background: #FFFFFF;
    border-radius: 8px;
    margin: 0 12px;
    @media screen and (max-width: 400px) {
        margin: 24px 12px;
    }
`

export const LoginFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 16px 16px 32px;
`

export const ImageContainer = styled.div`
    min-height: 240px;
    background-image: url(${logoBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ImageLogo = styled.img`
    width: 40%;
`;

export const LoginHeading = styled.h2`
    font-size: 15px;
`;

export const LoginDetailsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
`;

export const LoginOptionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E5E5E5;
    border-radius: 4px;
    margin-bottom: 1rem;
    padding: 8px;
`;
interface Props {
    selected: boolean;
}
export const LoginOption = styled.div`
    background: ${(props:Props) => props.selected ? "#ffffff" : "transparent"};
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
    transition: transform 2s, background .5s;
`

export const Container = styled.div`
    display: ${(props:Props) => props.selected ? "flex" : "none"};
    margin-bottom: 24px;
`;

export const FormContainer = styled(Form)`
    width: 100%
`

export const EmailContainer = styled(FormItem)`
    display: ${(props:Props) => props.selected  ? "block" : "none"};
`;

export const CustomEmailInput = styled(Input)`
    border-radius: 4px;
    height: 48px;
`

export const PasswordContainer = styled(Input.Password)`
    height: 48px;
`

export const CustomInput = styled.input`
    color: #888888;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    padding: 12px;
    border-radius: 4px;
    outline: none;
    margin: 0 auto;


    :focus {
        border: 1px solid #40a9ff;
        outline: 0;
        box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    }

    :hover {
        border: 1px solid #40a9ff;
    }

    ::placeholder {
        opacity: .5;
    }
`;

export const CustomSelect = styled.select`
    padding: 0;
    color: #888888;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    outline: none;
    margin-right: 8px;
    width: 40%;
    :focus {
        border: 1px solid #40a9ff;
        outline: 0;
        box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    }

    :hover {
        border: 1px solid #40a9ff;
    }
`

export const CustomButton = styled.button`
    background-color: #ff895c;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border: rgba(165, 181, 175, .15) 2px solid;
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    outline: none;
    margin-top: 8px;
    cursor: pointer;
    :disabled {
        background-color: #E5E5E5;
        cursor: no-drop;
      }
`;

export const LoginIconContainerBody = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 16px;
`;

export const LoginIconContainer = styled.span`
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%; 
    box-shadow: 0 0 10px #bfbfbf;
`;

export const LoginIcon = styled.img``;