import React, {useState} from 'react';
import * as S from '@/Pages/Login/login.styles';
import {Form, message} from 'antd';
import { useLocation } from 'react-router-dom';
import {facebookAuthProvider,googleAuthProvider} from '@/config/authMethod'
//Images
import logo from '@/assets/images/logo.png';
import googleIcon from "@/assets/images/google.svg";
import facebookIcon from "@/assets/images/facebook.svg";

import firebase from "@/config/firebaseConfig";
import { ILoginRequestBody} from '@/modules/authTypes';
import {useGetLoginByAccountMutation} from "@/services/authApi";
import {encrypt} from "@/utils/dataSecure";
import crypto from "crypto";

const countryCodes = require('country-codes-list')

const Login  = () => {

    const [form] = Form.useForm();
    const [selected, setSelected] = useState(true);
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [countryCode, setCountryCode] = useState('+49');
    const [loginByAccount,result] = useGetLoginByAccountMutation()

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();

    const onSelected = (num: boolean) => {
        setSelected(num);
        if (num) {
            setPhoneNumber('');
            setCountryCode('+49');
        } else {
            setEmail('');
        }
        setPassword('');
        form.resetFields();
    }
    const allCountryCodes: string[] = Object.values(countryCodes.customList('countryNameEn', `+{countryCallingCode}`));
    const CountryCodesNameObject: { [key: string]: string } = countryCodes.customList('countryCallingCode', "{countryNameEn}");

    const handleLogin = () => {
        let data: ILoginRequestBody;
				const state = window.location.search.replace(/\?state=/, '');
        // console.log('state', state);
        if (email === '') {
            data = {
                "type": "phone",
                // "phoneNumber": countryCode + phoneNumber,
                "userName": countryCode + phoneNumber,
                "password": password,
                "state": state,
            }
        } else {
            data = {
                // "email": email,
                "type": "email",
                "userName": email,
                "password": password,
                "state": state,
            }
        }
				// signInAlexa encrypt(JSON.stringify(data))
				// let _url = `/ifttt/auth/oauth2/authorized/login?type=${data.type}&userName=${data.userName}&password=${data.password}&state=${data.state}`;
				// let _data = `type=${data.type}&userName=${encodeURI(data.userName||'')}&password=${data.password}&state=${data.state}`;
				// console.log('_data', _data)
        loginByAccount({url:"/ifttt/auth/oauth2/authorized/login", data: data}).unwrap().then(res => {
						console.log('res', res, res.data);
            if (res && res.code ===100) {
							window.location.assign(res.data);
                // window.location.assign(`${query.get("redirect_uri")}#state=${query.get("state")}&access_token=${res.data.accessToken}&token_type=Bearer`)
            } else {
                message.warn(res && res.msg ? res.msg : "Account not linked")
            }
        }).catch((error)=> {
            message.error("Account not linked")
        })
    }


    const handleOnClick = async (authProvider: firebase.auth.AuthProvider)=>{

        firebase.auth().signInWithPopup(authProvider).then((res) => {
                //TODO uid  possible maybe null，we need to handle it
                let uid = firebase.auth().currentUser?.uid;
                let authMethod =res.credential?.signInMethod
                if (!uid) {
                    message.warn(`Unable to linked ${authMethod} `)
                    return
                }
                let data = {
                    "uid": uid,
                }
                loginByAccount({url: "socialSignInAlexa", data: encrypt(JSON.stringify(data))}).unwrap().then(res => {
                    if (res && res.success) {
                        window.location.assign(`${query.get("redirect_uri")}#state=${query.get("state")}&access_token=${res.data.accessToken}&token_type=Bearer`)
                    } else {
                        message.error("Account not linked")
                    }
                }).catch((error) => {
                    message.error("Account not linked")

                })
            }

            )
        }


    return (
        <S.LoginContainer>
            <S.LoginInnerContainer>
                <S.ImageContainer>
                    <S.ImageLogo src={logo} alt="logo" />
                </S.ImageContainer>
                <S.LoginFormContainer>
                    <S.LoginHeading>
                        {/* Connecting to IFTTT */}
                        Connecting to IFTTT to Control your devices
                    </S.LoginHeading>
                    <S.LoginDetailsContainer>
                        <S.LoginOptionContainer >
                            <S.LoginOption selected={selected} onClick={() => onSelected(true)} >
                                Email Address
                            </S.LoginOption>
                            <S.LoginOption selected={!selected} onClick={() => onSelected(false)} >
                                Phone Number
                            </S.LoginOption>
                        </S.LoginOptionContainer>
                        <S.FormContainer
                            onFinish={handleLogin}
                            form={form}
                        >
                            <S.EmailContainer
                                selected={selected}
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message: "Enter a valid email id"
                                    },
                                ]}
                            >
                                <S.CustomEmailInput
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e:any) => setEmail(e.target.value)}
                                />
                            </S.EmailContainer>
                            <S.Container selected={!selected}>
                                <S.CustomSelect
                                    value={countryCode}
                                    onChange={(e:any) => setCountryCode(e.target.value)} >
                                    {allCountryCodes.map((country, index) =>
                                        <option key={index} value={country}>{CountryCodesNameObject[country.substring(1)]}{country}</option>
                                    )}
                                </S.CustomSelect>
                                <S.CustomInput
                                    pattern="[0-9]*"
                                    placeholder="Phone Number"
                                    type="text"
                                    name="phoneNumber"
                                    id=""
                                    value={phoneNumber}
                                    onChange={(e:any ) => {
                                        if (e.target.validity.valid) {
                                            setPhoneNumber(e.target.value)
                                        }
                                    }}
                                />
                            </S.Container>
                            <Form.Item
                                name="password"
                            >
                                <S.PasswordContainer
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e:any) => setPassword(e.target.value)}
                                />
                            </Form.Item>
                            <S.CustomButton disabled={!(password && (email || (countryCode && phoneNumber)))} >
                                Link
                            </S.CustomButton>
                            <S.LoginIconContainerBody >
                                <S.LoginIconContainer
                                    onClick={() => handleOnClick(googleAuthProvider)}
                                >
                                    <S.LoginIcon src={googleIcon} alt="googleIcon" />
                                </S.LoginIconContainer>
                                <S.LoginIconContainer
                                    onClick={() => handleOnClick(facebookAuthProvider)}
                                >
                                    <S.LoginIcon src={facebookIcon} alt="facebookIcon" />
                                </S.LoginIconContainer>
                            </S.LoginIconContainerBody>
                        </S.FormContainer>
                    </S.LoginDetailsContainer>
                </S.LoginFormContainer>
            </S.LoginInnerContainer>
        </S.LoginContainer>
    )
}

export default Login