import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/analytics";
import { getAnalytics,logEvent,EventParams} from "firebase/analytics";
const firebaseConfig = {
    apiKey: "AIzaSyCNQuk3fYeo3SR0tTKpGLVddPYpZ8wEIZ4",
    authDomain: "cloudhawk-15f49.firebaseapp.com",
    databaseURL: "https://cloudhawk-15f49.firebaseio.com",
    projectId: "cloudhawk-15f49",
    storageBucket: "cloudhawk-15f49.appspot.com",
    messagingSenderId: "292854447107",
    appId: "1:292854447107:web:54b0781a386f7c30f7a236",
    measurementId: "G-261JRXGW6E"
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const logE= ( name: string, data: EventParams)=>{
    logEvent(analytics,name,{...data})
}

export default firebase;