import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

const isLocal = !true;

const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY
const REACT_APP_AUTH_URL = process.env.NODE_ENV === 'development' ? isLocal ? 'http://192.168.1.178:8810/' : 'https://cloudhawk-test.yunext.com/' : process.env.REACT_APP_AUTH_URL
if (!REACT_APP_API_KEY){
    throw new Error("REACT_APP_API_KEY is null")
}
if (!REACT_APP_AUTH_URL){
    throw new Error("REACT_APP_AUTH_URL is null")
}
const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: fetchBaseQuery({
        baseUrl: REACT_APP_AUTH_URL,
        prepareHeaders: (headers) => {
            let apiKey = REACT_APP_API_KEY;
            // @ts-ignore
            let loginDetails = JSON.parse(localStorage.getItem("token"));
            let AUTHENTICATION = (loginDetails === null || loginDetails === undefined) ? "" : loginDetails;
            // headers.set("Content-Type", "application/json");
            headers.set("Content-Type", "application/json;charset=utf-8");
            // headers.set("Content-Type", "application/x-www-form-urlencoded");
            headers.set("Cache-Control", "no-cache");
            headers.set("x-api-key", apiKey);
            headers.set("authorization", AUTHENTICATION);
            headers.set("Accept", "application/json");
            return headers
        },
    }),
    endpoints: () => ({})
})
export default baseApi